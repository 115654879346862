/* about.css */

/* Animation for sliding in from the right */
@keyframes slideInRight {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 0.8;
      transform: translateX(0);
    }
  }
  
  .animate-slideInRight {
    opacity: 0;
    transform: translateX(100%);
    animation: slideInRight 2.5s ease-in-out forwards;
  }
  
  .animate-slideInRight.visible {
    opacity: 1;
    transform: translateX(0);
  }
  .about-section {
    transition: all 0.3s ease-in-out;
  }
  
  .about-section:hover {
    transform: scale(1.02);
  }
  
  .about-section h2 span {
    color: #ffcc00; /* Adjusted for better visibility */
  }
  
  .about-button {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .about-button:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  
  /* Fade-in effect for text */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-fadeIn {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 1.5s ease-in-out forwards;
  }
  